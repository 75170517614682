import React from "react";

import { SEO } from "../components/seo";

const Page = (): JSX.Element => (
  <>
    <SEO
      title="404"
      description="Die gewünschte Seite konnte leider nicht gefunden werden"
    />
    <div className="max-w-xl mx-auto px-6 my-20 text-center">
      <h1 className="text-4xl font-bold mb-4">404</h1>
      <p>Die gewünschte Seite konnte leider nicht gefunden werden.</p>
    </div>
  </>
);

export default Page;
